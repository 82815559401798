import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataSource, { API_URL } from "../../helpers/DataSource";
import GetParam from '../../helpers/GetParam';
import EasyEdit from 'react-easy-edit';
import moment from "moment";

const UserTimesheet = () => {
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [history, setHistory] = useState([]);

    useEffect(() => {
        load();
    }, []);

    const load = () => {
        DataSource('auth/GetEndUserById/?Id=' + GetParam(2)).then(res => {
            setUserInfo(res.data);
            DataSource('json/historyByStore/?storeId=' + GetParam(3) + '&from=' + GetParam(4) + '&to=' + GetParam(5) + '&xid=' + GetParam(2)).then(res => {
                setHistory(res.data);
            });
        });
    }

    const save = (id, time, type) => {
        DataSource('json/UpdateTimesheet/?id=' + id + '&time=' + time + '&type=' + type).then(res => {
            load();
        });
    }

    return (
        <div className='d-flex flex-column justify-content-center align-items-center flex-row-auto'>
            <div className="header w-100 d-flex flex-row align-items-center justify-content-between px-2">
                <span style={{ width: '20px' }} onClick={() => navigate('/stores')}>
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                    </svg>
                </span>
                <span style={{ fontSize: '15px' }}>{userInfo.fullName}</span>
                <span></span>
            </div>
            <div className="w-100 px-2">
                <table className='table table-md table-bordered border hover-table'>
                    <thead>
                        <tr tyle={{ lineHeight: '25px' }}>
                            <th className='p-1' width="40">Ngày</th>
                            <th className='p-1' width="150">Giờ vào</th>
                            <th className='p-1' width="150">Giờ ra</th>
                            <th className='text-end p-1' width="80">Số giờ</th>
                            <th className='text-end p-1' width="150">Chi nhánh</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            history.map((item, index) => {
                                return (
                                    <tr style={{ lineHeight: '25px' }} key={index}>
                                        <td className='p-1'>
                                            {moment(item.checkIn).format('DD/MM')}
                                        </td>
                                        <td className='text-start p-1'>
                                            <span className='p-0'>{item.checkIn === null ? <span className='text-danger px-0'>???</span> : (
                                                <EasyEdit
                                                    type="text"
                                                    value={moment(item.checkIn).format('HH:mm')}
                                                    onSave={(value) => { save(item.id, value, "checkin") }}
                                                    saveButtonLabel="💾"
                                                    cancelButtonLabel="❌"
                                                    hideSaveButton={true}
                                                    hideCancelButton={true}
                                                    saveOnBlur={true}
                                                    onValidate={(value) => {
                                                        return value != null && value?.trim().length !== 0;
                                                    }}
                                                />
                                            )}</span>
                                        </td>
                                        <td className='text-start p-1'>
                                            <span className='p-0'>{item.checkOut === null ? <span className='text-danger px-0'>???</span> : (
                                                <EasyEdit
                                                    type="text"
                                                    value={moment(item.checkOut).format('HH:mm')}
                                                    onSave={(value) => { save(item.id, value, "checkout") }}
                                                    saveButtonLabel="💾"
                                                    cancelButtonLabel="❌"
                                                    hideSaveButton={true}
                                                    hideCancelButton={true}
                                                    saveOnBlur={true}
                                                    onValidate={(value) => {
                                                        return value != null && value?.trim().length !== 0;
                                                    }}
                                                />
                                            )}</span>
                                        </td>
                                        <td className='text-end p-1'>
                                            {Number(item.totalHours).toFixed(1)}
                                        </td>
                                        <td className='text-end p-1'>
                                            {item.storeName}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        <tr tyle={{ lineHeight: '25px' }}>
                            <td colSpan={3} className='p-1'>
                                <strong className='p-0'>Tổng giờ làm:</strong>
                            </td>
                            <td className='text-end p-1'>
                                <strong className='p-0'>
                                    {
                                        history.reduce((prev, current) => {
                                            return prev + current.totalHours;
                                        }, 0).toFixed(1)
                                    }
                                </strong>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserTimesheet;