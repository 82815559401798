import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import DataSource from "../../helpers/DataSource";
import { toast } from "react-toastify";
import EasyEdit from 'react-easy-edit';
import moment from "moment";
import DatePicker from "react-date-picker";

const Ingredient = () => {
    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const [storeId, setStoreId] = useState(null);
    const [data, setData] = useState([]);
    const [date, setDate] = useState(moment());
    const [history, setHistory] = useState([]);
    const [model, setModel] = useState({
        Name: null,
        Unit: null,
        Price: null,
        CategoryName: null
    });
    const [selectedItem, setSelectedItem] = useState(null);
    const [openHistory, setOpenHistory] = useState(false);

    useEffect(() => {
        getAll();
        DataSource('odata/stores?$orderby=CreatedDate').then(res => {
            var foo = [];
            for (var index in res.data.value) {
                foo.push({ value: res.data.value[index].Id, label: res.data.value[index].Name });
            }
            setStores(foo);
            setStoreId(foo[0].value);
        });
    }, []);

    const handleChangeStringModel = (e) => {
        setModel(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
    }

    const handleChangeNumberModel = (e) => {
        setModel(prevState => ({ ...prevState, [e.target.name]: parseInt(e.target.value) }))
    }

    const getAll = () => {
        DataSource('odata/Ingredient/?$orderBy=CategoryName,Id').then(res => {
            setData(res.data.value);
        });
    }

    useEffect(() => {
        DataSource('odata/Transfer/IngredientId/?IngredientId=' + selectedItem?.Id + '&date=' + moment(date).format("yyyy-MM-DDTHH:mm:ss") + 'Z&StoreId=' + storeId + '&$orderBy=CreatedDate%20desc').then(res => {
            setHistory(res.data.value);
            console.log(res.data.value);
        });
    },[date, storeId]);

    const submit = () => {
        if (Object.values(model).every(o => o === null)) {
            toast.error("Yêu cầu nhập đầy đủ thông tin!");
        } else {
            DataSource('odata/Ingredient', 'POST', model).then(res => {
                toast.success("Tạo tạo thành công!");
                setModel({
                    Name: '',
                    Unit: '',
                    Price: 0,
                    CategoryName: ''
                });
                getAll();
            });
        }
    }

    const save = (item, value, target) => {
        if (target === "Price") {
            DataSource('odata/Ingredient', 'PUT', { ...item, [target]: parseInt(value) }).then(res => {
                getAll();
            });
        } else {
            DataSource('odata/Ingredient', 'PUT', { ...item, [target]: value }).then(res => {
                getAll();
            });
        }
    }

    const dateChange = (value) => {
        setDate(value);
    }

    return (
        <div className='d-flex flex-column justify-content-center align-items-center flex-row-auto'>
            <div className="header w-100 d-flex flex-row align-items-center justify-content-between px-2">
                <span style={{ width: '10px' }} onClick={() => navigate('/')}>
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                    </svg>
                </span>
                <span style={{ fontSize: '18px', paddingLeft: '38px' }}>Kho nguyên liệu</span>
                <span className="align-items-end">
                    <Button onClick={submit}>Thêm</Button>
                </span>
            </div>
            <div className="container">
                <Row>
                    <Col className="px-1">
                        <Label>
                            Hàng hóa
                        </Label>
                        <Input
                            name="Name"
                            type="text"
                            value={model.Name}
                            className="form-control-sm"
                            onChange={handleChangeStringModel}
                        />
                    </Col>
                    <Col className="px-1">
                        <Label>
                            Đơn vị tính
                        </Label>
                        <Input
                            name="Unit"
                            type="text"
                            value={model.Unit}
                            className="form-control-sm"
                            onChange={handleChangeStringModel}
                        />
                    </Col>
                    <Col className="px-1">
                        <Label>
                            Đơn giá
                        </Label>
                        <Input
                            name="Price"
                            type="number"
                            value={model.Price}
                            className="form-control-sm"
                            onChange={handleChangeNumberModel}
                        />
                    </Col>
                    <Col className="px-1">
                        <Label>
                            Hạng mục
                        </Label>
                        <Input
                            name="CategoryName"
                            type="text"
                            value={model.CategoryName}
                            className="form-control-sm"
                            onChange={handleChangeStringModel}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="px-1">
                        <div className="d-flex flex-wrap table-responsive">
                            <Table className="border mt-1">
                                <thead>
                                    <tr>
                                        <td style={{ minWidth: '30px', width: '30px' }}></td>
                                        <td style={{ minWidth: '130px' }}>Hàng hóa</td>
                                        <td style={{ minWidth: '90px', width: '50px' }} className="text-end">Đơn vị</td>
                                        <td style={{ minWidth: '90px', width: '50px' }} className="text-end">Đơn giá</td>
                                        <td style={{ minWidth: '100px', width: '100px' }} className="text-end">Hạng mục</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map((item) => {
                                            return (
                                                <tr>
                                                    <td><span style={{ cursor: 'pointer' }} onClick={() => {
                                                        setSelectedItem(item);
                                                        setOpenHistory(p => !p);
                                                        if(openHistory === false) {
                                                            DataSource('odata/Transfer/IngredientId/?IngredientId=' + item.Id + '&date=' + moment(date).format("yyyy-MM-DDTHH:mm:ss") + 'Z&StoreId=' + storeId + '&$orderBy=CreatedDate%20desc').then(res => {
                                                                setHistory(res.data.value);
                                                            });
                                                        }
                                                    }}>🕑</span></td>
                                                    <td>
                                                        <EasyEdit
                                                            type="text"
                                                            value={item.Name}
                                                            onSave={(value) => { save(item, value, "Name") }}
                                                            saveButtonLabel="💾"
                                                            cancelButtonLabel="❌"
                                                            hideSaveButton={true}
                                                            hideCancelButton={true}
                                                            saveOnBlur={true}
                                                            onValidate={(value) => {
                                                                return value != null && value?.trim().length !== 0;
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <EasyEdit
                                                            type="text"
                                                            value={item.Unit}
                                                            onSave={(value) => { save(item, value, "Unit") }}
                                                            saveButtonLabel="💾"
                                                            cancelButtonLabel="❌"
                                                            hideSaveButton={true}
                                                            hideCancelButton={true}
                                                            saveOnBlur={true}
                                                            className="text-end"
                                                            onValidate={(value) => {
                                                                return value != null && value?.trim().length !== 0;
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <EasyEdit
                                                            type="text"
                                                            value={item.Price}
                                                            onSave={(value) => { save(item, value, "Price") }}
                                                            saveButtonLabel="💾"
                                                            cancelButtonLabel="❌"
                                                            hideSaveButton={true}
                                                            hideCancelButton={true}
                                                            saveOnBlur={true}
                                                            onValidate={(value) => {
                                                                return value != null;
                                                            }}
                                                        />
                                                    </td>
                                                    <td className="text-end">
                                                        <EasyEdit
                                                            type="text"
                                                            value={item.CategoryName}
                                                            onSave={(value) => { save(item, value, "CategoryName") }}
                                                            saveButtonLabel="💾"
                                                            cancelButtonLabel="❌"
                                                            hideSaveButton={true}
                                                            hideCancelButton={true}
                                                            saveOnBlur={true}
                                                            onValidate={(value) => {
                                                                return value != null && value?.trim().length !== 0;
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={openHistory} size="lg">
                <ModalHeader toggle={() => setOpenHistory(p => !p)}>
                    {selectedItem?.Name}
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex flex-wrap">
                        {
                            stores.map((item, index) => {
                                return (
                                    <div key={index} className="store-radioButton">
                                        <input
                                            id={index}
                                            name='storeRadio'
                                            type="radio"
                                            value={item.value}
                                            checked={storeId === item.value}
                                            onChange={(e) => {
                                                setStoreId(item.value);
                                            }}
                                        />
                                        <label for={index}>
                                            {item.label}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <DatePicker format="dd/MM/yyyy" value={date} onChange={(value) => dateChange(value)} />
                    <div className="d-flex flex-wrap table-responsive">
                            <Table className="border mt-1">
                                <thead>
                                    <tr>
                                        <td style={{ minWidth: '10px', width: '30px' }}></td>
                                        <td style={{ minWidth: '50px', width: '140px' }}></td>
                                        <td style={{ minWidth: '20px', width: '100px', textAlign: 'left' }}>Số lượng</td>
                                        <td>Hàng hóa</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        history.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.TransferType === 'NHAP' ? <span style={{color:'#50cd89'}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.4 14.8L5.3 6.69999C4.9 6.29999 4.9 5.7 5.3 5.3C5.7 4.9 6.29999 4.9 6.69999 5.3L14.8 13.4L13.4 14.8Z" fill="currentColor" />
                                                        <path opacity="0.3" d="M19.8 8.5L8.5 19.8H18.8C19.4 19.8 19.8 19.4 19.8 18.8V8.5Z" fill="currentColor" />
                                                    </svg></span> : <span style={{color:'#f1416c'}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.4 10L19.5 18.1C19.9 18.5 19.9 19.1 19.5 19.5C19.1 19.9 18.5 19.9 18.1 19.5L10 11.4L11.4 10Z" fill="currentColor" />
                                                        <path opacity="0.3" d="M5 16.3L16.3 5H6C5.4 5 5 5.4 5 6V16.3Z" fill="currentColor" />
                                                    </svg></span>}
                                                    </td>
                                                    <td>{moment(item.CreatedDate).format('DD-MM-YYYY HH:mm')}</td>
                                                    <td style={{textAlign: 'left'}}>{item.Quantity}</td>
                                                    <td>{item.IngredientName} ({item.IngredientUnit})</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setOpenHistory(p => !p)}>Đóng</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Ingredient;