import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export class Layout extends Component {
  render() {
    return (
      <div className='d-flex flex-column flex-root pt-3'>
        {this.props.children}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
        />
      </div>
    );
  }
}
