import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataSource, { API_URL } from "../helpers/DataSource";
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { Button } from "reactstrap";
import DateTimeForURL from "../helpers/DateTimeForURL";

const GetTaxFile = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [storeId, setStoreId] = useState(null);
    const [from, setFrom] = useState(moment().startOf('isoDay'));
    const [to, setTo] = useState(moment().add(1, 'days').endOf('isoDay'));

    useEffect(() => {
        DataSource('json/GetStoreDataPOS').then(res => {
            var foo = [];
            for (var index in res.data) {
                foo.push({ value: res.data[index].id, label: res.data[index].name });
            }
            setData(foo);
            setStoreId(foo[0].value);
        });
    }, []);

    const dateChange = (value, target) => {
        if (target === 'from') {
            setFrom(value)
        } else if (target === 'to') {
            setTo(value);
        };
    }

    const getFile = () => {
        window.open(API_URL + 'json/TaxFile/?storeId=' + storeId + '&from=' + DateTimeForURL(from) + '&to=' + DateTimeForURL(to), "_self");
    }

    return (
        <div className='d-flex flex-column justify-content-center align-items-center flex-row-auto'>
            <div className="header w-100 d-flex flex-row align-items-center justify-content-between px-2">
                <span style={{ width: '20px' }} onClick={() => navigate('/')}>
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                    </svg>
                </span>
                <span style={{ fontSize: '18px' }}>Khai báo thuế</span>
                <span></span>
            </div>
            <div className="container">
                <div className="d-flex flex-wrap">
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className="store-radioButton">
                                    <input
                                        id={index}
                                        name='storeRadio'
                                        type="radio"
                                        value={item.value}
                                        checked={storeId === item.value}
                                        onChange={(e) => {
                                            setStoreId(item.value);
                                        }}
                                    />
                                    <label htmlFor={index}>
                                        {item.label}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="border rounded py-2 px-1 mb-1 d-flex gap-3">
                    <div>
                        <label className="me-2">Từ ngày:</label>
                        <DatePicker format="dd/MM/yyyy" value={from} onChange={(value) => dateChange(value, 'from')} />
                    </div>
                    <div>
                        <label className="me-2">Đến ngày:</label>
                        <DatePicker format="dd/MM/yyyy" value={to} onChange={(value) => dateChange(value, 'to')} />
                    </div>
                </div>
                <Button className="btn-sm" onClick={getFile}>Tải file khai báo thuế</Button>
            </div>
        </div>
    );
}
export default GetTaxFile;