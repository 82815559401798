import GetTaxFile from "./components/GetTaxFile";
import Home from "./components/Home";
import Ingredient from "./components/Inventory/Ingredient";
import StoreTimesheet from "./components/Timesheet/StoreTimesheet";
import UserTimesheet from "./components/Timesheet/UserTimesheet";
import Report from "./components/Inventory/Report";
import Product from "./components/Inventory/Product";
import Transfer from "./components/Inventory/Transfer";

const AppRoutes = [
  {
    index: true,
    path: '/',
    element: <Home />
  },
  {
    path: '/stores',
    element: <StoreTimesheet />
  },
  {
    path: '/user/:id/:storeId/:from/:to',
    element: <UserTimesheet />
  },
  {
    path: '/taxfile',
    element: <GetTaxFile />
  },
  {
    path: '/ingredient',
    element: <Ingredient />
  },
  {
    path: '/transfer',
    element: <Transfer />
  },
  {
    path: '/report',
    element: <Report />
  },
  {
    path: '/product',
    element: <Product />
  }
];

export default AppRoutes;
