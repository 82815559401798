import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap";
import DataSource from "../../helpers/DataSource";

const Product = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [ingredient, setIngredient] = useState([]);
    const [selectedItem, setSeletectedItem] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        DataSource('Product/List').then(res => {
            setData(res.data);
        });
    }, []);

    const save = (item, e) => {
        DataSource('IngredientProduct/AddDetail?CreateUserId=' + localStorage.getItem('userId'), 'POST', { ...item, quantity: parseFloat(e.target.value) }).then(res => {
            console.log(res.status);
        });
    }

    const open = (item) => {
        setIngredient([]);
        setSeletectedItem(item);
        DataSource('IngredientProduct/GetDetail?ProductId=' + item.id).then(res => {
            setIngredient(res.data);
        });
        setOpenModal(prevState => !prevState);
    }

    return (
        <div className='d-flex flex-column justify-content-center align-items-center flex-row-auto'>
            <div className="header w-100 d-flex flex-row align-items-center justify-content-between px-2">
                <span style={{ width: '10px' }} onClick={() => navigate('/')}>
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                    </svg>
                </span>
                <span style={{ fontSize: '18px', paddingLeft: '38px' }}>Kho sản phẩm</span>
                <span className="align-items-end">
                </span>
            </div>
            <div className="container">
                <div className="d-flex flex-wrap table-responsive">
                    <Table className="border mt-1">
                        <thead>
                            <tr>
                                <th style={{ width: '60%' }}>Sản phẩm</th>
                                <th style={{ minWidth: '90px', textAlign: 'right', paddingRight: '5px' }}>Liên kết</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.name}</td>
                                            <td className="text-end">
                                                <span onClick={() => open(item)}>Cấu hình</span>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <Modal isOpen={openModal} size="lg">
                <ModalHeader>
                    Cấu hình nguyên liệu cho {selectedItem?.name}
                </ModalHeader>
                <ModalBody>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{ width: '20%' }}>Mục</th>
                                <th style={{ width: '40%' }}>Tên</th>
                                <th colSpan={2} style={{ width: '90px' }}>Tồn kho</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ingredient.map((item) => {
                                    return (
                                        <tr>
                                            <td style={{ verticalAlign: 'middle' }}>{item.ingredientCategoryName}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{item.ingredientName}</td>
                                            <td style={{ verticalAlign: 'middle', padding: 0 }}>
                                                <Input
                                                    onBlur={(e) => save(item, e)}
                                                    defaultValue={item.quantity}
                                                    size="sm"
                                                    style={{ padding: '0 5px', border: 0, background: '#ffffdd' }}></Input>
                                            </td>
                                            <td className="px-2" style={{ verticalAlign: 'middle' }}>{item.ingredienUnit}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setOpenModal(prevState => !prevState)}>Đóng</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Product;