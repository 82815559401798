import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "reactstrap";
import DataSource from "../../helpers/DataSource";
import EasyEdit from 'react-easy-edit';
import DatePicker from 'react-date-picker';
import DateTimeForURL from "../../helpers/DateTimeForURL";

const Report = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [storeId, setStoreId] = useState(null);
    const [productList, setProductList] = useState([]);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        if (localStorage.getItem('roles') === 'RQC') {
            DataSource('odata/stores?$orderby=CreatedDate').then(res => {
                var foo = [];
                for (var index in res.data.value) {
                    foo.push({ value: res.data.value[index].Id, label: res.data.value[index].Name });
                }
                setData(foo);
                setStoreId(foo[0].value);
                DataSource('odata/ReportInventory/?date=' + DateTimeForURL(date) + '&StoreId=' + foo[0].value).then(res => {
                    setProductList(res.data.value);
                });
            });
        } else {
            setStoreId(parseInt(localStorage.getItem('storeId')));
            DataSource('odata/ReportInventory/?date=' + DateTimeForURL(date) + '&StoreId=' + parseInt(localStorage.getItem('storeId'))).then(res => {
                setProductList(res.data.value);
            });
        }
    }, []);

    useEffect(() => load(), [storeId, date]);

    const load = () => {
        DataSource('odata/ReportInventory/?date=' + DateTimeForURL(date) + '&StoreId=' + storeId + '&$orderby=CategoryName').then(res => {
            setProductList(res.data.value);
        });
    }

    const save = (item, value, target) => {
        if (value === null || value === "")
            return false;
        DataSource('odata/ReportInventory/', 'POST', { ...item, [target]: parseFloat(value), CreatUserId: parseInt(localStorage.getItem("userId")), StoreId: storeId }).then(res => {
            load();
        });
        console.log(item);
    }

    const dateChange = (value) => {
        setDate(value);
    }

    return (
        <div className='d-flex flex-column justify-content-center align-items-center flex-row-auto'>
            <div className="header w-100 d-flex flex-row align-items-center justify-content-between px-2">
                <span style={{ width: '10px' }} onClick={() => navigate('/')}>
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                    </svg>
                </span>
                <span style={{ fontSize: '18px' }}>Báo cáo tồn</span>
                <span className="align-items-end">
                </span>
            </div>
            <div className="container">
                {
                    localStorage.getItem('roles') === 'RQC' && (
                        <>
                            <div className="d-flex flex-wrap">
                                {
                                    data.map((item, index) => {
                                        return (
                                            <div key={index} className="store-radioButton">
                                                <input
                                                    id={index}
                                                    name='storeRadio'
                                                    type="radio"
                                                    value={item.value}
                                                    checked={storeId === item.value}
                                                    onChange={(e) => {
                                                        setStoreId(item.value);
                                                    }}
                                                />
                                                <label htmlFor={index}>
                                                    {item.label}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <DatePicker format="dd/MM/yyyy" value={date} onChange={(value) => dateChange(value, 'date')} />
                        </>
                    )
                }
                <div className="table-responsive mt-1">
                    <Table className="border">
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }}>Mục</th>
                                <th style={{ width: "30%" }}>Hàng hóa</th>
                                <th style={{ width: "20%" }}>Sáng</th>
                                <th style={{ width: "20%" }}>Đã dùng</th>
                                <th style={{ width: "20%" }}>Tối</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                productList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item.CategoryName}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{item.IngredientName}</td>
                                            <td>
                                                <EasyEdit
                                                    type="text"
                                                    value={item.QuantityBuoiSang}
                                                    onSave={(value) => { save(item, value, "QuantityBuoiSang") }}
                                                    saveButtonLabel="💾"
                                                    cancelButtonLabel="❌"
                                                    hideSaveButton={true}
                                                    hideCancelButton={true}
                                                    saveOnBlur={true}
                                                    onValidate={(value) => {
                                                        return value != null;
                                                    }}
                                                />
                                            </td>
                                            <td>0</td>
                                            <td>
                                                <EasyEdit
                                                    type="text"
                                                    value={item.QuantityBuoiToi}
                                                    onSave={(value) => { save(item, value, "QuantityBuoiToi") }}
                                                    saveButtonLabel="💾"
                                                    cancelButtonLabel="❌"
                                                    hideSaveButton={true}
                                                    hideCancelButton={true}
                                                    saveOnBlur={true}
                                                    onValidate={(value) => {
                                                        return value != null;
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    )
}

export default Report;