import axios from 'axios';
import uuidv4 from './uuidv4';
import GetParam from './GetParam';

export const API_URL = process.env.NODE_ENV === 'production' ? 'https://x-api.bobanbo.vn/' : 'https://localhost:7232/';

export default function DataSource(
    endpoint,
    method = "GET",
    body,
    contentType = "application/json; charset=utf8;") {

    const StoreId = parseInt(localStorage.getItem("StoreId"));
    const TenantId = localStorage.getItem("TenantId");
    const Token = localStorage.getItem("Token");
    const UUIDv4 = uuidv4();
    const Domain = GetParam(0);

    if (method === "POST" && body.Id === null) {
        body.Id = -1;
    }

    if (method === "PUT") {
        delete body['@odata.context'];
    }

    return axios({
        headers: {
            'Content-Type': contentType,
            Accept: "application/json",
            TenantId: "6bfc9f20d7f047669409b0750050c59b",
            StoreId: StoreId,
            Token: Token,
            RequestId: UUIDv4,
            Domain: Domain
        },
        method: method,
        url: `${API_URL}${endpoint}`,
        data: JSON.stringify(body)
    });
}
