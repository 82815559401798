import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import DataSource from "../../helpers/DataSource";
import moment from "moment";

const Transfer = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState([]);
    const [history, setHistory] = useState([]);
    const [model, setModel] = useState({
        TransferType: 'NHAP',
        StoreId: parseInt(localStorage.getItem('storeId'))
    });

    useEffect(() => {
        getAll();
    }, []);

    const handleChangeModal = (e) => {
        setModel(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleChangeFloatValueModal = (e) => {
        setModel(prevState => ({ ...prevState, [e.target.name]: parseFloat(e.target.value) }));
    }

    const handleSelect = (e) => {
        setModel(prevState => ({ ...prevState, 'IngredientId': e.value }));
    }

    useEffect(() => {
        console.log(model);
    }, [JSON.stringify(model)]);

    const submit = () => {
        DataSource('odata/Transfer?CreateUserId=' + localStorage.getItem('userId'), 'POST', model).then(res => {
            alert('Đã tạo phiếu thành công!');
        });
    }

    const getAll = () => {
        var foo = [];
        DataSource('odata/Ingredient/?$orderBy=CategoryName').then(res => {
            for (var i in res.data.value) {
                foo.push({
                    value: res.data.value[i].Id,
                    label: res.data.value[i].Name + ' (' + res.data.value[i].Unit + ')'
                })
            }
            setData(foo);
        });
    }

    useEffect(() => {
        DataSource('odata/Transfer/?StoreId=' + localStorage.getItem('storeId') + '&$orderBy=CreatedDate%20desc').then(res => {
            console.log(res.data.value);
            setHistory(res.data.value);
        });
    }, []);

    return (
        <>
            <div className='d-flex flex-column justify-content-center align-items-center flex-row-auto'>
                <div className="header w-100 d-flex flex-row align-items-center justify-content-between px-2">
                    <span style={{ width: '10px' }} onClick={() => navigate('/')}>
                        <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                            <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                        </svg>
                    </span>
                    <span style={{ fontSize: '18px', paddingLeft: '38px' }}>Nhập/xuất kho nguyên liệu</span>
                    <span className="align-items-end">
                        <Button onClick={() => setOpenModal(prevState => !prevState)}>Thêm</Button>
                    </span>
                </div>
            </div>
            <div className="container">
                <Row>
                    <Col xs={12} className="px-1">
                        <div className="d-flex flex-wrap table-responsive">
                            <Table className="border mt-1">
                                <thead>
                                    <tr>
                                        <td style={{ minWidth: '10px', width: '30px' }}></td>
                                        <td style={{ minWidth: '50px', width: '140px' }}></td>
                                        <td style={{ minWidth: '20px', width: '100px', textAlign: 'left' }}>Số lượng</td>
                                        <td>Hàng hóa</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        history.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.TransferType === 'NHAP' ? <span style={{color:'#50cd89'}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.4 14.8L5.3 6.69999C4.9 6.29999 4.9 5.7 5.3 5.3C5.7 4.9 6.29999 4.9 6.69999 5.3L14.8 13.4L13.4 14.8Z" fill="currentColor" />
                                                        <path opacity="0.3" d="M19.8 8.5L8.5 19.8H18.8C19.4 19.8 19.8 19.4 19.8 18.8V8.5Z" fill="currentColor" />
                                                    </svg></span> : <span style={{color:'#f1416c'}}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.4 10L19.5 18.1C19.9 18.5 19.9 19.1 19.5 19.5C19.1 19.9 18.5 19.9 18.1 19.5L10 11.4L11.4 10Z" fill="currentColor" />
                                                        <path opacity="0.3" d="M5 16.3L16.3 5H6C5.4 5 5 5.4 5 6V16.3Z" fill="currentColor" />
                                                    </svg></span>}
                                                    </td>
                                                    <td>{moment(item.CreatedDate).format('DD-MM-YYYY HH:mm')}</td>
                                                    <td style={{textAlign: 'left'}}>{item.Quantity}</td>
                                                    <td>{item.IngredientName} ({item.IngredientUnit})</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
            <Modal isOpen={openModal}>
                <ModalHeader toggle={() => setOpenModal(prevState => !prevState)}>
                    Ghi phiếu nhập/xuất
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label>Mặt hàng:</Label>
                        <Select options={data} onChange={handleSelect} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Phiếu NHAP/XUAT:</Label>
                        <Input name="TransferType" defaultValue={model.TransferType} onChange={handleChangeModal}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Số lượng:</Label>
                        <Input name="Quantity" type="number" defaultValue={model.Quantity} onChange={handleChangeFloatValueModal}></Input>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={submit}>Hoàn tất</Button>
                    <Button onClick={() => setOpenModal(prevState => !prevState)}>Đóng</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
export default Transfer;