import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Offcanvas, OffcanvasBody, OffcanvasHeader, Table } from 'reactstrap';
import DataSource from '../helpers/DataSource';
import { toast } from 'react-toastify';
import moment from 'moment';
import DateTimeForURL from '../helpers/DateTimeForURL';
import { useNavigate } from 'react-router-dom';
import GetParam from '../helpers/GetParam';

const Home = () => {
  const navigate = useNavigate();
  const [pass, setPass] = useState('');
  const [users, setUsers] = useState([]);
  const [openHistory, setOpenHistory] = useState(false);
  const [history, setHistory] = useState([]);
  const [roles, setRoles] = useState("");
  const [userId, setUserId] = useState(null);
  const [store, setStore] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(moment().week());
  const [currentYear, setCurrentYear] = useState(moment().year());
  const [from, setFrom] = useState(moment().startOf('isoWeek'));
  const [to, setTo] = useState(moment().endOf('isoWeek'));
  const [total, setTotal] = useState(0);

  const [openMenu, setOpenMenu] = useState(false);
  const toggleOpenMenu = () => {
    if (openMenu === true) {
      localStorage.removeItem("openMenu");
    }
    setOpenMenu(prevState => !prevState);
  }

  useEffect(() => {
    getUsers();
    if (localStorage.getItem("openMenu") === 'true') {
      setRoles(localStorage.getItem('roles'));
      setOpenMenu(true);
    }
    console.log(GetParam(0));
  }, []);

  const getUsers = () => {
    DataSource('odata/Timesheet/GetUserListByStore?$orderby=CheckIn%20desc').then(res => {
      setUsers(res.data.value);
    });
    if (GetParam(0) !== 'go.bobanbo.vn') {
      DataSource('json/GetStore').then(res => {
        setStore(res.data);
        localStorage.setItem('storeId', res.data.id);
      });
    } else {
      setStore({ name: 'Central', address: 'HCMC' });
    }
  }

  const check = (type) => {
    if (type === 'in') {
      DataSource('auth/checkio', 'POST', {
        pin: pass,
        type: 'in'
      }).then((res) => {
        if (res.data === -1) {
          toast.error("Mã PIN không chính xác hoặc không truy cập hệ thống tại cửa hàng. Báo sai quá 3 lần sẽ bị khóa truy cập trong 48h.");
        } else if (res.data === -2) {
          toast.error("Không tìm thấy phiên checkin");
        } else if (res.data === -4) {
          DataSource('auth/GetEndUserByPin/?pin=' + pass).then(res => {
            setRoles(res.data.roles);
            setUserId(res.data.id);
            localStorage.setItem("userId", res.data.id);
            localStorage.setItem("roles", res.data.roles);
            localStorage.setItem("openMenu", true);
            toggleOpenMenu();
          });
        } else if (res.data === -5) {
          toast.error("Chưa trong khung giờ checkin");
        } else {
          toast.success("Đã checkin thành công!");
        }
        getUsers();
      });
    } else {
      DataSource('auth/checkio', 'POST', {
        pin: pass,
        type: 'out',
      }).then((res) => {
        if (res.data === -1) {
          toast.error("Mã PIN không chính xác");
        } else if (res.data === -2) {
          toast.error("Không tìm thấy phiên checkin");
        } else if (res.data === -3) {
          toast.error("Bạn đã quên checkout ở phiên làm việc trước!");
        }
        else {
          toast.success("Đã làm được " + Number(res.data).toFixed(1) + " giờ");
        }
        getUsers();
      });
    }
    setPass('');
  }

  const previousWeek = () => {
    setCurrentWeek(currentWeek - 1);
  }

  const nextWeek = () => {
    setCurrentWeek(currentWeek + 1);
  }

  useEffect(() => {
    setFrom(moment().year(currentYear).week(currentWeek).startOf('isoWeek'));
    setTo(moment().year(currentYear).week(currentWeek).endOf('isoWeek'));
  }, [currentWeek]);

  useEffect(() => {
    loadHistory();
  }, [from, to]);

  const loadHistory = () => {
    DataSource('json/history/?from=' + DateTimeForURL(from) + '&to=' + DateTimeForURL(to) + '&xid=' + userId).then(res => {
      setHistory(res.data);
    });
  }

  const toggleOpenHistory = () => {
    if (openHistory === false) {
      loadHistory();
    }
    setOpenHistory(prev => !prev);
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center flex-row-auto'>
      <img src="/logo.svg" width={210} style={{ marginTop: '65px' }} className='mb-4' />
      {
        store !== null ? (
          <>
            <div className='text-center px-3 mb-3'>
              <label className='fw-bold'>{store?.name}</label><br />
              <label>{store?.address}</label>
            </div>
            <label className='text-danger'>Your pin code</label>
            <Input
              value={pass}
              className='form-control text-center mt-2 mb-2'
              type='password'
              onChange={(e) => {
                setPass(e.target.value);
              }}
              style={{ fontSize: '20px', color: 'gray', width: '90%' }}
            />
            <div className='d-flex'>
              <Button
                className='me-1 btn-success'
                disabled={pass.length < 6}
                onClick={() => check('in')}>
                Checkin
              </Button>
              <Button
                className='btn-danger'
                disabled={pass.length < 6}
                onClick={() => check('out')}>
                Checkout
              </Button>
            </div>
          </>
        ) : (
          <label className='text-danger text-center my-5 mx-3'>Đang xác định chi nhánh...<br />(Vui lòng kiểm tra kết nối mạng đúng quy định)</label>
        )
      }

      {
        store !== null && (
          <div className='d-flex text-center' style={{ marginTop: '50px' }}>
            {
              users.length > 0 ? (
                users.map((item, index) => {
                  return (
                    <div key={index} className="d-flex flex-column bg-secondary text-white py-2 px-3" style={{ borderRadius: '3px', marginRight: '3px', marginBottom: '3px' }}>
                      <span className='text-white'>{item.UserName}</span>
                      <span className='text-white'>{moment(item.CheckIn).format('HH:mm')}</span>
                    </div>
                  )
                })
              ) : (
                <span>Chưa có ai trong ca</span>
              )
            }
          </div>
        )
      }
      <Modal isOpen={openMenu} fade={false} fullscreen centered>
        <ModalHeader toggle={toggleOpenMenu}>
          Menu
        </ModalHeader>
        <ModalBody>
          <ul className='mmenu'>
            <li onClick={() => {
              if (roles === 'RQC') {
                navigate('/stores')
              } else {
                toggleOpenHistory();
              }
            }}>Kiểm tra giờ làm</li>
            {roles === 'RQC' && (<li onClick={() => navigate('/product')}>Kho sản phẩm</li>)}
            {roles === 'RQC' && (<li onClick={() => navigate('/ingredient')}>Kho nguyên liệu</li>)}
            {roles === 'RQC' && (<li onClick={() => navigate('/transfer')}>Nhập/xuất kho</li>)}
            {roles === 'RQC' && (<li onClick={() => navigate('/report')}>Báo cáo tồn</li>)}
            {roles === 'RQC' && (<li onClick={() => navigate('/taxfile')}>Khai báo thuế</li>)}
          </ul>
        </ModalBody>
      </Modal>

      <Modal isOpen={openHistory} fade={false} fullscreen centered>
        <ModalHeader className='w-100 border-0 pb-0'>
          <div className='d-flex w-100 justify-content-between align-items-center'>
            <span onClick={previousWeek}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
                <path d="M12.0657 12.5657L14.463 14.963C14.7733 15.2733 14.8151 15.7619 14.5621 16.1204C14.2384 16.5789 13.5789 16.6334 13.1844 16.2342L9.69464 12.7029C9.30968 12.3134 9.30968 11.6866 9.69464 11.2971L13.1844 7.76582C13.5789 7.3666 14.2384 7.42107 14.5621 7.87962C14.8151 8.23809 14.7733 8.72669 14.463 9.03696L12.0657 11.4343C11.7533 11.7467 11.7533 12.2533 12.0657 12.5657Z" fill="currentColor" />
              </svg>
            </span>
            <h6 className='mb-0 fw-bold'>Tuần {currentWeek}</h6>
            <span onClick={nextWeek}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
                <path d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z" fill="currentColor" />
              </svg>
            </span>
          </div>
          <div className='text-center py-0' style={{ marginTop: '-10px' }}>
            <span style={{ fontSize: '12px', textAlign: 'center', width: '100%' }}>{(from).format('DD/MM/YYYY')} - {(to).format('DD/MM/YYYY')}</span>
          </div>
        </ModalHeader>
        <ModalBody className='px-3'>
          <table className='table table-md table-bordered border'>
            <thead>
              <tr tyle={{ lineHeight: '25px' }}>
                <th className='p-1'>Ngày</th>
                <th className='p-1'>Giờ vào</th>
                <th className='p-1'>Giờ ra</th>
                <th className='p-1'>Số giờ</th>
                <th className='text-end p-1'>Chi nhánh</th>
              </tr>
            </thead>
            <tbody>
              {
                history.map((item, index) => {
                  return (
                    <tr style={{ lineHeight: '25px' }} key={index}>
                      <td className='p-1'>
                        {moment(item.checkIn).format('DD/MM')}
                      </td>
                      <td className='text-start p-1'>
                        <span className='p-0'>{item.checkIn === null ? <span className='text-danger px-0'>???</span> : moment(item.checkIn).format('HH:mm')}</span>
                      </td>
                      <td className='text-start p-1'>
                        <span className='p-0'>{item.checkOut === null ? <span className='text-danger px-0'>???</span> : moment(item.checkOut).format('HH:mm')}</span>
                      </td>
                      <td className='text-center p-1'>
                        {Number(item.totalHours).toFixed(1)}
                      </td>
                      <td className='text-end p-1'>
                        {item.storeName}
                      </td>
                    </tr>
                  )
                })
              }
              <tr tyle={{ lineHeight: '25px' }}>
                <td colSpan={3} className='p-1'>
                  <strong className='p-0'>Tổng giờ làm:</strong>
                </td>
                <td className='text-center p-1'>
                  <strong className='p-0'>
                    {
                      history.reduce((prev, current) => {
                        return prev + current.totalHours;
                      }, 0).toFixed(1)
                    }
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter className='d-flex justify-content-center'>
          <Button className='px-5' onClick={toggleOpenHistory}>Đóng</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default Home;