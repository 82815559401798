import { useEffect, useState } from "react";
import DataSource from "../../helpers/DataSource";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DateTimeForURL from "../../helpers/DateTimeForURL";
import { Table } from "reactstrap";

const StoreTimesheet = () => {
    const [data, setData] = useState([]);
    const [timesheet, setTimeshee] = useState([]);
    const navigate = useNavigate();
    const [currentWeek, setCurrentWeek] = useState(moment().week());
    const [currentYear, setCurrentYear] = useState(moment().year());
    const [storeId, setStoreId] = useState(null);
    const [from, setFrom] = useState(moment().startOf('isoWeek'));
    const [to, setTo] = useState(moment().endOf('isoWeek'));

    useEffect(() => {
        DataSource('odata/stores?$orderby=CreatedDate').then(res => {
            var foo = [];
            for (var index in res.data.value) {
                foo.push({ value: res.data.value[index].Id, label: res.data.value[index].Name });
            }
            setData(foo);
            setStoreId(foo[0].value);
        });
    }, []);

    const previousWeek = () => {
        setCurrentWeek(currentWeek - 1);
    }

    const nextWeek = () => {
        setCurrentWeek(currentWeek + 1);
    }

    useEffect(() => {
        setFrom(moment().year(currentYear).week(currentWeek).startOf('isoWeek'));
        setTo(moment().year(currentYear).week(currentWeek).endOf('isoWeek'));
    }, [currentWeek]);

    useEffect(() => {
        loadLog();
    }, [from, to, storeId]);

    const loadLog = () => {
        console.log(storeId + ' - ' + from + ' - ' + to);
        if (storeId !== null) {
            DataSource('odata/Timesheet/GetTimesheetByStore/?storeId=' + storeId + '&from=' + DateTimeForURL(from) + '&to=' + DateTimeForURL(to)).then(res => {
                console.log(res.data.value);
                setTimeshee(res.data.value);
            });
        }
    }

    return (
        <div className='d-flex flex-column justify-content-center align-items-center flex-row-auto'>
            <div className="header w-100 d-flex flex-row align-items-center justify-content-between px-2">
                <span style={{ width: '20px' }} onClick={() => navigate('/')}>
                    <svg width="34" height="34" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
                    </svg>
                </span>
                <span style={{ fontSize: '18px' }}>Thống kê giờ làm</span>
                <span></span>
            </div>
            <div className="container">
                <div className="d-flex flex-wrap">
                    {
                        data.map((item, index) => {
                            return (
                                <div key={index} className="store-radioButton">
                                    <input
                                        id={index}
                                        name='storeRadio'
                                        type="radio"
                                        value={item.value}
                                        checked={storeId === item.value}
                                        onChange={(e) => {
                                            setStoreId(item.value);
                                        }}
                                    />
                                    <label for={index}>
                                        {item.label}
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="border rounded py-2 px-1 mb-1">
                    <div className='d-flex w-100 justify-content-between align-items-center'>
                        <span onClick={previousWeek} style={{ opacity: '.6' }}>
                            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
                                <path d="M12.0657 12.5657L14.463 14.963C14.7733 15.2733 14.8151 15.7619 14.5621 16.1204C14.2384 16.5789 13.5789 16.6334 13.1844 16.2342L9.69464 12.7029C9.30968 12.3134 9.30968 11.6866 9.69464 11.2971L13.1844 7.76582C13.5789 7.3666 14.2384 7.42107 14.5621 7.87962C14.8151 8.23809 14.7733 8.72669 14.463 9.03696L12.0657 11.4343C11.7533 11.7467 11.7533 12.2533 12.0657 12.5657Z" fill="currentColor" />
                            </svg>
                        </span>
                        <span className="text-center">
                            <h6 className='mb-0 fw-bold'>Tuần {currentWeek}</h6>
                            <div className='text-center py-0'>
                                <span style={{ fontSize: '12px', textAlign: 'center', width: '100%' }}>{(from).format('DD/MM/YYYY')} - {(to).format('DD/MM/YYYY')}</span>
                            </div>
                        </span>
                        <span onClick={nextWeek} style={{ opacity: '.6' }}>
                            <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor" />
                                <path d="M11.9343 12.5657L9.53696 14.963C9.22669 15.2733 9.18488 15.7619 9.43792 16.1204C9.7616 16.5789 10.4211 16.6334 10.8156 16.2342L14.3054 12.7029C14.6903 12.3134 14.6903 11.6866 14.3054 11.2971L10.8156 7.76582C10.4211 7.3666 9.7616 7.42107 9.43792 7.87962C9.18488 8.23809 9.22669 8.72669 9.53696 9.03696L11.9343 11.4343C12.2467 11.7467 12.2467 12.2533 11.9343 12.5657Z" fill="currentColor" />
                            </svg>
                        </span>
                    </div>
                </div>
                <Table className="border">
                    <thead>
                        <tr>
                            <th>
                                Nhân viên
                            </th>
                            <th>
                                Giờ làm
                            </th>
                            <th>
                                Lương
                            </th>
                            <th width={5}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {timesheet.length > 0 && (
                            timesheet.map((item, index) => {
                                return (
                                    <tr>
                                        <td>
                                            {item.UserName}
                                        </td>
                                        <td>
                                            {Number(item.TotalHours).toFixed(1)}
                                        </td>
                                        <td>
                                            {Number(item.SalaryByHourly).toFixed(0).toLocaleString(undefined, {minimumFractionDigits: 0})}
                                        </td>
                                        <td>
                                            <span onClick={() => navigate('/user/' + item.Id + '/' + storeId + '/' + DateTimeForURL(from) + '/' + DateTimeForURL(to))}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="currentColor" />
                                                </svg>
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                        )}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default StoreTimesheet;